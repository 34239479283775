import React, { useState } from "react";
import {
  Table,
  Paper,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ThreeDots } from "react-loader-spinner";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { colors } from "../../../theme/colors";
import { formatDate } from "../../../components/common/dateFormat";
import DataNotFound from "../../../assets/images/No Data Found.png";
import { ReactComponent as Edit } from "../../../assets/images/Edit.svg";
import Pagination from "../../../components/common/Pagination";
import EditSubscriptionModal from "./editSubscriptionModal";

const SubscriptionDataTable = ({
  handleChangeRowsPerPage,
  columnName,
  subscriptionData,
  tableRef,
  loading,
  totalCount,
  rowsPerPage,
  page,
  handleChangePage,
  logInUserDetails,
}) => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [editSubscriptionModal, setEditSubscriptionModal] = useState(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#242323",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      color: "#686465",
      fontWeight: 400,
    },
  }));

  const handlePointerDown = (event) => {
    setIsMouseDown(true);
    setStartX(event.clientX || (event.touches && event.touches[0]?.clientX));
    document.body.style.cursor = "grabbing";
  };

  const handlePointerMove = (event) => {
    if (isMouseDown && tableRef.current) {
      const scrollX =
        startX -
        (event.clientX || (event.touches && event.touches[0]?.clientX));
      tableRef.current.scrollLeft += scrollX;
      setStartX(event.clientX || (event.touches && event.touches[0]?.clientX));
    }
  };

  const handlePointerUp = () => {
    setIsMouseDown(false);
    document.body.style.cursor = "default";
  };

  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{
          width: "100% !important",
          boxShadow: "none",
          borderRadius: "12px",
          cursor: "pointer",
          overflowX: "auto",

          touchAction: "none",
        }}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUp}
        onTouchStart={handlePointerDown}
        onTouchMove={handlePointerMove}
        onTouchEnd={handlePointerUp}
        ref={tableRef}
      >
        <Table
          aria-label="simple table"
          sx={{
            width: "100%",
            "& th, td": {
              padding: "22px 22px",
            },
          }}
        >
          <TableHead>
            <TableRow sx={{ "& th": { whiteSpace: "nowrap" } }}>
              {columnName?.map((item, index) => (
                <StyledTableCell
                  align="start"
                  sx={{ fontSize: "18px" }}
                  key={index}
                >
                  {item?.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow sx={{ textAlign: "center" }}>
                <TableCell colSpan={12}>
                  <Box
                    sx={{
                      textAlign: "center",
                      "& > div": { justifyContent: "center" },
                    }}
                  >
                    <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color={colors.theme}
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody
              sx={{
                "& tr:last-child td": {
                  borderBottom: 0,
                },
                color: colors?.basics?.normal,
              }}
            >
              {subscriptionData?.subscriptions?.length > 0 ? (
                subscriptionData?.subscriptions?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      textAlign: "center",
                      color: colors?.basics?.normal,

                      "& td": { whiteSpace: "nowrap" },
                    }}
                  >
                    <StyledTableCell>
                      <Typography className="recent-table">
                        {row?.subscription?.p_login?.charAt(0).toUpperCase() +
                          row?.subscription?.p_login?.slice(1)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography className="recent-table">
                        {row?.subscription?.planName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.subscription?.parentName
                        ? row?.subscription?.parentName
                        : "Self"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.subscription?.ownerName
                        ? row?.subscription?.ownerName
                        : "Self"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.subscription?.productTypeName
                        ? row?.subscription?.productTypeName
                        : "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.subscription?.price}
                    </StyledTableCell>
                    <StyledTableCell>
                      ${row?.subscription?.unitPrice}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.subscription?.volume}GB
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatDate(row?.subscription?.startDate)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {formatDate(row?.subscription?.endDate)}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.usage ? row?.usage : "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.subscription?.transactionId
                        ? row?.subscription?.transactionId
                        : "-"}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.subscription?.paymentMethod}
                    </StyledTableCell>

                    <StyledTableCell>
                      {row?.subscription?.planStatus}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row?.subscription?.autoRenewal}
                    </StyledTableCell>
                    <TableCell>
                      <Button
                        sx={{
                          width: "48px",
                          background: "#ffffff",
                          height: "48px",
                          fontSize: "20px",
                          fontWeight: 700,
                          border: "1px solid #FD337E",
                          textTransform: "capitalize",
                          borderRadius: "6px",
                          boxShadow: "0 0 0 0 !important",
                          color: "#FD337E ",
                          "&:hover": {
                            backgroundColor: "#FD337E",
                          },
                        }}
                        onClick={() => {
                          setEditSubscriptionModal({ status: true, row: row });
                        }}
                      >
                        <Edit />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow sx={{ width: "100%", height: "100%" }}>
                  <TableCell
                    colSpan={14}
                    sx={{ textAlign: "center", border: "0" }}
                  >
                    <img
                      src={DataNotFound}
                      alt="data not found"
                      style={{ width: "80px", height: "80px" }}
                    />
                    <Typography>Data not found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {editSubscriptionModal.status ? (
        <EditSubscriptionModal
          isOpen={editSubscriptionModal.status}
          onClose={() => setEditSubscriptionModal({ status: false, row: null })}
          subscriptionData={editSubscriptionModal.row.subscription}
        />
      ) : null}
      <Box>
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalCount={totalCount}
        />
      </Box>
    </Box>
  );
};

export default SubscriptionDataTable;
